
import { Component, Vue } from 'vue-property-decorator';
import CMSUser from '@/models/CMSUser';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import Schedule from '@/models/Schedule';
import ScheduleRepository from '@/repositories/ScheduleRepository';
import Resource from '@/models/Resource';
import CalendarScheduleEdit from './CalendarScheduleEdit.vue';
import { RouteNames } from '@/enums/routes/RouteNames';
import ResourceRepository from '@/repositories/ResourceRepository';
import Rule from '@/models/Rule';

@Component({
    name: 'CalendarSchedules',
    components: {
        CalendarScheduleEdit,
    },
})
export default class CalendarSchedules extends Vue {
    private loading = false;
    private selectedUser: string = '';
    private selectedResource: string = '';
    private activeTab: string = 'users';

    private get fields() {
        return [
            {
                title: 'ID',
                key: 'id',
                dataIndex: 'id',
                sortable: true,
            },
            this.activeTab === 'users'
                ? {
                      title: this.$t('User'),
                      key: 'userId',
                      dataIndex: 'userId',
                      sortable: true,
                      scopedSlots: {
                          customRender: 'user',
                      },
                  }
                : {
                      title: this.$t('Resource'),
                      key: 'resourceId',
                      dataIndex: 'resourceId',
                      sortable: true,
                      scopedSlots: {
                          customRender: 'resource',
                      },
                  },
            {
                title: this.$t('Naziv'),
                key: 'title',
                dataIndex: 'title',
                sortable: true,
            },
            {
                title: this.$t('Akcije'),
                key: 'actions',
                sortable: false,
                scopedSlots: {
                    customRender: 'actions',
                },
            },
        ];
    }

    private get users() {
        return CMSUserRepository.getAll();
    }

    private get resources() {
        return ResourceRepository.getAll();
    }

    private get schedules() {
        return ScheduleRepository.getAll();
    }

    private async mounted() {
        try {
            this.loading = true;
            await Promise.all([CMSUser.getAll(), Resource.getAll(), this.retrieveAllSchedules()]);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        } finally {
            this.loading = false;
        }

        await Rule.getAll();
    }

    private async retrieveAllSchedules() {
        if (this.selectedUser && this.selectedUser !== '' && this.activeTab === 'users') {
            await this.getCalendarUserSchedules();
            return;
        }

        if (this.selectedResource && this.selectedResource !== '' && this.activeTab === 'resources') {
            await this.getCalendarResourceSchedules();
            return;
        }

        try {
            await Schedule.getAll();
        } catch (e) {
            return Promise.reject(e);
        } finally {
            this.loading = false;
        }
    }
    private async getCalendarUserSchedules() {
        if (!this.selectedUser || this.selectedUser === '') {
            await this.retrieveAllSchedules();
            return;
        }
        this.loading = true;
        try {
            await Schedule.getByUserId(this.selectedUser);
            this.loading = false;
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loading = false;
            return Promise.reject();
        }
    }
    private async getCalendarResourceSchedules() {
        if (!this.selectedResource || this.selectedResource === '') {
            await this.retrieveAllSchedules();
            return;
        }
        this.loading = true;
        try {
            await Schedule.getByResourceId(this.selectedResource);
            this.loading = false;
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loading = false;
            return Promise.reject();
        }
    }

    private getUserName(id: number) {
        if (!this.users || !id) {
            return '-';
        }
        return this.users.find((u) => u.id === id.toString())?.name ?? '-';
    }
    private getResourceName(id: string) {
        if (!this.resources || !id) {
            return '-';
        }
        return this.resources.find((u) => u.id === id)?.title ?? '-';
    }
    private async onTabChange(e: string) {
        this.activeTab = e;
        await this.retrieveAllSchedules();
    }
    private async deleteSchedule(id: string) {
        this.loading = true;
        await Schedule.deleteExisting(id);

        this.$notification.success({
            message: this.$t('Schedule successfully deleted!') as string,
            description: '',
        });

        this.retrieveAllSchedules();
    }
    private editItem(item: Schedule) {
        const type = item.userId !== null ? 'user' : item.resourceId ? 'resource' : null;
        const id = item.id;
        if (id) {
            this.$router.push({
                name: RouteNames.schedulesResources,
                params: { id: id.toString(), dynamic: 'CalendarScheduleEdit' },
                query: { type },
            });
        }
    }

    private addUserItem() {
        this.$router.push({
            name: RouteNames.schedulesResources,
            params: { dynamic: 'CalendarScheduleEdit', userId: this.selectedUser },
            query: { type: 'user' },
        });
    }
    private addResourceItem() {
        this.$router.push({
            name: RouteNames.schedulesResources,
            params: { dynamic: 'CalendarScheduleEdit', resourceId: this.selectedResource },
            query: { type: 'resource' },
        });
    }
}
